import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from 'react-emotion';
import { PostFullHeader, PostFullTitle, NoImage, PostFull } from '../templates/post';
import { PostFullContent } from '../components/PostContent';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';
import config from '../website-config';

const PageTemplate = css`
  .site-main {
    background #fff;
    padding-bottom: 4vw;
  }
`;


const SantaBarbaraSoftware: React.SFC = () => (
  <IndexLayout>
    <Helmet>
      <title>{`Santa Barbara Software | ${config.title}`}</title>
      <meta name="description" content={config.seoDescription} />
    </Helmet>
    <Wrapper className={`${PageTemplate}`}>
      <header className={`${SiteHeader} ${outer}`}>
        <div className={`${inner}`}>
          <SiteNav />
        </div>
      </header>
      <main id="site-main" className={`site-main ${SiteMain} ${outer}`}>
        <article className={`${PostFull} post page ${NoImage}`}>
          <PostFullHeader>
            <PostFullTitle>Santa Barbara Software</PostFullTitle>
          </PostFullHeader>

          <PostFullContent className="post-full-content">
            <div className="post-content">
              <h1>Portfolio of Websites</h1>
              <h3>Music</h3>
              <p>
                <h6><a target="_blank" href={'https://www.musicvideobuddy.com/'}>musicvideobuddy.com</a></h6>
                <h6><a target="_blank" href={'https://www.playlistbuddyapp.com/'}>playlistbuddyapp.com</a></h6>
                <h6><a target="_blank" href={'https://www.playlistbuddy.com/'}>playlistbuddy.com</a></h6>
                <h6><a target="_blank" href={'https://www.powerhourtube.com/'}>powerhourtube.com</a></h6>

              </p>
              <h3>Health and Fitness</h3>
              <p>
                <h6><a target="_blank" href={'https://www.walsteadfitness.com/'}>walsteadfitness.com</a></h6>
                <h6><a target="_blank" href={'https://www.beautifulskindoctor.com/'}>beautifulskindoctor.com</a></h6>
                <h6><a target="_blank" href={'https://www.manlyfacecare.com/'}>manlyfacecare.com</a></h6>
                <h6><a target="_blank" href={'https://www.mccrayfitnesssystems.com/'}>mccrayfitnesssystems.com</a></h6>
                <h6><a target="_blank" href={'https://www.mindfulgiraffe.com/'}>mindfulgiraffe.com</a></h6>
                <h6><a target="_blank" href={'https://www.scottysurfs.com/'}>scottysurfs.com</a></h6>
              </p>
              <h3>Style</h3>
              <p>
                <h6><a target="_blank" href={'https://www.themanlylook.com/'}>themanlylook.com</a></h6>
              </p>
              <h3>Technology</h3>
              <p>
                <h6><a target="_blank" href={'https://www.devmeetsconf.com/'}>devmeetsconf.com</a></h6>

              </p>
              <h3>Travel and Languages</h3>
              <p>
                <h6><a target="_blank" href={'https://www.scottytravels.com/'}>scottytravels.com</a></h6>

              </p>
              <h3>Stocks and Finance</h3>
              <p>
                <h6><a target="_blank" href={'https://www.stockpickbuddy.com/'}>stockpickbuddy.com</a></h6>
              </p>
              <p>
                Scott Walstead<br />
                Santa Barbara Software Founder<br />
                <a target="_blank" href={'https://www.santabarbarasoftware.com/'}>santabarbarasoftware.com</a>
              </p>
            </div>
          </PostFullContent>
        </article>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
);

export default SantaBarbaraSoftware;
